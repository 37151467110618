import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: window.localStorage.getItem('token') ? window.localStorage.getItem('token') : null,
    phone: window.localStorage.getItem('phone') ? window.localStorage.getItem('phone') : null,
    userName: window.localStorage.getItem('userName') ? window.localStorage.getItem('userName') : null,
    userId: window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : null,
    career: window.localStorage.getItem('career') ? window.localStorage.getItem('career') : null,
    careerId: window.localStorage.getItem('careerId') ? window.localStorage.getItem('id') : null,
    modeType:'interviews',
    xunfeiAppId:'',
    xunfeiSecretKey:'',
    skillList: [],
    writtenItemData:{},
    prompt:'',
    writtenUploadFile:null,
    writtenScreenshotFile:null,
  },
  getters,
  mutations: {
    setToken: (state, data) => {
      state.token = data
      window.localStorage.setItem('token', data)
    },
    setPhone: (state, data) => {
      state.phone = data
      window.localStorage.setItem('phone', data)
    },
    setUserName: (state, data) => {
      state.userName = data
      window.localStorage.setItem('userName', data)
    },
    setUserId: (state, data) => {
      state.userId = data
      window.localStorage.setItem('userId', data)
    },
    setCareer: (state, data) => {
      state.career = data
      window.localStorage.setItem('career', data)
    },
    setCareerId: (state, data) => {
      state.careerId = data
      window.localStorage.setItem('careerId', data)
    },
    setModeType: (state, data) => {
      state.modeType = data
    },
    setXunfeiAppId: (state, data) => {
      state.xunfeiAppId = data
    },
    setXunfeiSecretKey: (state, data) => {
      state.xunfeiSecretKey = data
    },
    setSkillList: (state, data) => {
      state.skillList = data
    },
    setWrittenItemData: (state, data) => {
      state.writtenItemData = data
    },
    setPrompt: (state, data) => {
      state.prompt = data
    },
    setWrittenUploadFile: (state, data) => {
      state.writtenUploadFile = data
    },
    setWrittenScreenshotFile: (state, data) => {
      state.writtenScreenshotFile = data
    },
  },
  actions: {
    async login({ commit, dispatch }, data) {
      try {
        let { REQUEST } = await import('@/api/http')
        const res = await REQUEST.post('login', data.from, {
          isToken: data.isToken,
          isEncrypt: data.isEncrypt,
        })
        commit('setToken', res.data.access_token);
        commit('setPhone', data.from.phonenumber);
        await dispatch('getUserInfo', { phoneNo: data.from.phonenumber });
      } catch (err) {
        console.error('There was an error:', err);
      }
    },
    async getUserInfo({ commit }, data) {
      try {
        let { REQUEST } = await import('@/api/http')
        const res = await REQUEST.get('userInfo', data)
        res.data.id ? commit('setUserId', res.data.id) : null;
        res.data.userName ? commit('setUserName', res.data.userName) : null;
      } catch (err) {
        console.error('There was an error:', err);
      }
    },
    async logout({ commit }, data) {
      try {
        let { REQUEST } = await import('@/api/http')
        const res = await REQUEST.post('logout');
        commit('setToken', '');
        commit('setPhone', '');
        commit('setUserName', '');
        commit('setUserId', '');
        commit('setCareer', '');
        commit('setSkillList', '');
        window.localStorage.clear()
      } catch (err) {
        console.error('There was an error:', err);
      }
    },
    async writtenSubmit({ commit }, data) {
      let formData = new FormData();
      formData.append('file', data.file);

      try {
        let { REQUEST } = await import('@/api/http')
        const res = await REQUEST.post('written', formData);
        // 假设 res.data.remainingTimes 是你需要的数据
        commit('SET_TOTAL', res.data.remainingTimes);
      } catch (err) {
        console.error('There was an error submitting the file:', err);
      }
    },

  },
  modules: {},
  plugins: [
    // createPersistedState({
    //   storage: window.sessionStorage
    // })
  ]
})
export default store;
