
import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout/index.vue'
Vue.use(VueRouter)

export const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/',
    name: '',
    component: layout,
    children: [
      {
        meta: {
          icon: 'el-icon-s-home',
          title: '首页'
        },
        path: '/',
        name: 'home',
        component: () => import('../views/index.vue')
      },
      {
        meta: {
          title: '简历优化'
        },
        path: '/user/cv',
        name: 'cv',
        component: () => import('../views/user/CV.vue')
      },
      {
        meta: {
          title: '历史记录'
        },
        path: '/user/history',
        name: 'history',
        component: () => import('../views/user/history.vue')
      },
      {
        meta: {
          title: '历史记录详情'
        },
        path: '/user/historyDetail',
        name: 'historyDetail',
        component: () => import('../views/user/historyDetail.vue')
      },
      {
        meta: {
          title: '公告'
        },
        path: '/user/notice',
        name: 'notice',
        component: () => import('../views/user/notice.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 全局路由构造函数，判断是否登录和要跳转到页面
router.beforeEach((to, from, next) => {
  // console.log(to.path)
  if (to.path !== '/login') {
    let token = window.localStorage.getItem('token')
    if (!token) {
      next('/login')
    } else {
      // const userInfo = JSON.parse(window.localStorage.getItem('userInfo'))

      // if (userInfo.mobile !== 'admin') {
      //   next('/user/index')
      // } else {
      //   next()
      // }
      document.title = to.meta.title
      next()
    }
  } else if (to.path == '/login') {
    next()
  }
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
