
const getters = {
  token: state => state.token,
  phone: state => state.phone,
  userId: state => state.userId,
  userName: state => state.userName,
  career: state => state.career,
  skillList: state => state.skillList,
  careerId: state => state.careerId,
  modeType: state => state.modeType,
  xunfeiAppId: state => state.xunfeiAppId,
  xunfeiSecretKey: state => state.xunfeiSecretKey,
  writtenItemData: state => state.writtenItemData,
  prompt: state => state.prompt,
  writtenUploadFile: state => state.writtenUploadFile,
  writtenScreenshotFile: state => state.writtenScreenshotFile,
}

export default getters
