
<template>
  <div class="layout">
    <div class="header">
      <div class="left" style="padding-left: 20px">
        <el-button
          v-if="$route.path !== '/'"
          type="text"
          @click="$router.push('/')"
          ><i class="el-icon-arrow-left"></i> 返回首页</el-button
        >
        <!-- <el-button v-if="$route.path !== '/'" type="text" @click="$router.go(-1)"><i class="el-icon-arrow-left"></i>
            返回</el-button> -->
        <!-- <span style="margin-left: 20px;">{{ $route.meta.title }}</span> -->
      </div>
      <div class="center">
        <span class="vx">联系我们：{{ wx }}</span>
        <img class="logo" :src="require('@/assets/images/chat.png')" alt="" />
        <span class="publicize">{{ title }}</span>
      </div>
      <div class="rights">
        <el-dropdown @command="handleCommand">
          <div class="my">
            <img
              class="my-profile"
              :src="require('@/assets/images/my-profile.jpg')"
              alt=""
            />
            <span class="el-dropdown-link">
              {{ phone }}
            </span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">我的</el-dropdown-item>
            <el-dropdown-item command="2">简历优化</el-dropdown-item>
            <el-dropdown-item command="3">公告</el-dropdown-item>
            <el-dropdown-item command="4">帮助</el-dropdown-item>
            <!-- <el-dropdown-item command="5">历史记录</el-dropdown-item> -->
            <el-dropdown-item command="6" divided>退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <router-view></router-view>
    <LogOut ref="logOut" />
    <My ref="my" />
    <Help ref="help" />
  </div>
</template>

<script>
import LogOut from "@/components/logOut.vue";
import My from "@/components/my.vue";
import Help from "@/components/help.vue";

export default {
  components: { LogOut, My, Help },
  data() {
    return {
      wx: "18888888888",
      phone: "",
      title: "成为您最好的面试助手",
    };
  },
  mounted() {
    this.phone = this.$store.getters.phone;
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case "1":
          // 我的
          this.$refs.my.open();
          break;
        case "2":
          // 简历优化
          this.$router.push("/user/cv");
          break;
        case "3":
          // 公告
          this.$router.push("/user/notice");
          break;
        case "4":
          // 帮助
          this.$refs.help.open();
          break;
        case "5":
          // 历史记录
          this.$router.push("/user/history");
          break;
        case "6":
          // 退出
          this.$refs.logOut.open();
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  // align-items: center;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #ffffff;
    padding: 8px;
    box-sizing: border-box;

    .icon {
      margin-left: 20px;
      width: 20px;
    }

    .left {
      text-align: left;
    }

    .center {
      flex: 1;
      text-align: center;
      margin: 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .vx {
      }

      .logo {
        width: 40px;
        height: 40px;
        margin: 0 10px;
      }

      .publicize {
        font-size: 30px;
        font-weight: bold;
        background-image: linear-gradient(to right, #5f6eff, #51f710);
        -webkit-background-clip: text;
        color: transparent;
        background-clip: text;
      }
    }

    .rights {
      margin-right: 10px;
      cursor: pointer;

      .my {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .my-profile {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 6px;
      }
    }
  }
}
</style>