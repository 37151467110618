import request from './request'
import { HTTP_URI } from './api'
export const REQUEST = {
  post: function (urlName, datas, headers = { isToken: true }) {
    if (!HTTP_URI[urlName]) return
    return request({
      headers,
      url: HTTP_URI[urlName],
      method: 'post',
      data: datas
    })
  },
  put: function (urlName, datas, headers = { isToken: true }) {
    if (!HTTP_URI[urlName]) return
    return request({
      headers,
      url: HTTP_URI[urlName],
      method: 'put',
      data: datas
    })
  },
  get: function (urlName, params, headers = { isToken: true }) {
    return request({
      headers,
      url: HTTP_URI[urlName],
      method: 'get',
      params: params,
    })
  }
}
export default REQUEST
